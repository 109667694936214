const getOwnerShip = function(vlastnictvi){
  if(  vlastnictvi == 1){
    return 'private';
  }
  if(  vlastnictvi == 2){
    return 'cooperative';
  }
  if(  vlastnictvi == 4){
    return 'other';
  }
};

const getRating = function(state){
  if(  state == 1){
    return 'excellent';
  }
  if(  state == 2){
    return 'good';
  }
  if(  state == 3){
    return 'nothing_much';
  }
  if(  state == 4){
    return 'nothing_much';
  }
  if(  state == 5){
    return 'very_good';
  }
  if(  state == 6){
    return 'new';
  }
};

const getLocalType = function(type){
  if( type == 112 ){
    return '1+kk';
  }
  if( type == 113 ){
    return '1+1';
  }
  if( type == 122 ){
    return '2+kk';
  }
  if( type == 123 ){
    return '2+1';
  }
  if( type == 125 ){
    return '3+kk';
  }
  if( type == 126 ){
    return '3+1';
  }
  if( type == 132 ){
    return '4+kk';
  }
  if( type == 133 ){
    return '4+1';
  }
  return 'jiné';
};

const energy = function(label){
  if( label == 1 ){
    return 'A';
  }
  if( label == 2 ){
    return 'B';
  }
  if( label == 3 ){
    return 'C';
  }
  if( label == 4 ){
    return 'D';
  }
  if( label == 5 ){
    return 'E';
  }
  if( label == 6 ){
    return 'F';
  }
  if( label == 7 ){
    return 'G';
  }

  return null;
};

const getHouseType = function(type){
  if( type == 15 ){
    return 'family_house';
  }
  if( type == 16 ){
    return 'villa';
  }
  if( type == 560 ){
    return 'hut';
  }
  if( type == 561 ){
    return 'cottage';
  }
  if( type == 188 ){
    return 'other';
  }

  return null
}

const getConstruction = function(type){
  if( type == 1 ){
    return 'brick';
  }
  if( type == 2 ){
    return 'panel';
  }
  if( type == 3 ){
    return 'wood';
  }
  if( type == 35 ){
    return 'mixed';
  }
  if( type == 10 ){
    return 'other';
  }

  return null;
}

export default {
  getOwnerShip,
  getRating,
  getLocalType,
  energy,
  getHouseType,
  getConstruction
}