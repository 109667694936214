<template>
  <section class="multistep">
    <div class="container">
      <div class="steps-count">
        <div class="steps">
          <a @click="moveToStep('firstStep', true)" class="done">1</a>

          <a class="active uk-disabled">2</a>

          <a v-if="stepHasValues(3)" @click="moveToStep('thirdStep')">3</a>
          <a v-if="!stepHasValues(3)" class="uk-disabled">3</a>

          <a v-if="stepHasValues(4)" @click="moveToStep('fourthStep')">4</a>
          <a v-if="!stepHasValues(4)" class="uk-disabled">4</a>
        </div>
      </div>
      <div class="content">
        <h1>Technické parametry</h1>
        <form class="form-label form-js-label">

          <div class="list-content" v-if="zadanytyp === '6'">
            <div class="row">
              <h3>Plocha</h3>
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <fieldset :class="{error: !step.uzit_plocha && validated, succes: step.uzit_plocha}">
                    <input type="number" name="flat" id="flat" v-model="step.uzit_plocha">
                    <label for="flat" :class="step.uzit_plocha ? 'yes' : 'no'">Užitná plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-2">
                  <fieldset :class="{error: !step.plochapozem && validated, succes: step.plochapozem}">
                    <input type="number" name="flat2" id="flat2" v-model="step.plochapozem">
                    <label for="flat2" :class="step.plochapozem !== 'undefined' ? 'yes' : 'no'">Plocha pozemku (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <h3>Stav</h3>
              <fieldset :class="{error: !step.stav && validated, succes: step.stav}">
                <select class="uk-select" v-model="step.stav">
                  <option v-for="option in optionsStav" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Stavba</h3>
              <fieldset :class="{error: !step.postavenoz && validated, succes: step.postavenoz}">
                <select class="uk-select" v-model="step.postavenoz">
                  <option v-for="option in optionsPostaven" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Typ domu</h3>
              <fieldset :class="{error: !step.poloha && validated, succes: step.poloha}">
                <select class="uk-select" v-model="step.poloha">
                  <option v-for="option in optionsPoloha" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Vytápění</h3>
              <fieldset :class="{error: !step.vytapeni && validated, succes: step.vytapeni}">
                <select class="uk-select" v-model="step.vytapeni">
                  <option v-for="option in optionsVytapeni" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>

            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Sklep</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_sklep }" v-on:click="plocha_sklep = !plocha_sklep">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_sklep"
                            :class="{error: Boolean(step.plocha_sklep) && validated, succes: step.plocha_sklep > 0}">
                    <input type="number" name="flat3" id="flat3" v-model="step.plocha_sklep">
                    <label for="flat3" :class="step.plocha_sklep > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Lodžie</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_lodzie }"
                       v-on:click="plocha_lodzie = !plocha_lodzie">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_lodzie"
                            :class="{error: step.plocha_lodzie <= 0 && validated, succes: step.plocha_lodzie > 0}">
                    <input type="number" name="flat4" id="flat4" v-model="step.plocha_lodzie">
                    <label for="flat4" :class="step.plocha_lodzie > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Garáž</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: pocet_garaz }" v-on:click="pocet_garaz = !pocet_garaz">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="pocet_garaz"
                            :class="{error: step.pocet_garaz <= 0 && validated, succes: step.pocet_garaz > 0}">
                    <input type="number" name="flat5" id="flat5" v-model="step.pocet_garaz">
                    <label for="flat5" :class="step.pocet_garaz > 0 ? 'yes' : 'no'">Počet</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Bazén</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: bazen }" v-on:click="bazen = !bazen">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="bazen" :class="{error: step.bazen <= 0 && validated, succes: step.bazen > 0}">
                    <input type="number" name="flat6" id="flat6" v-model="step.bazen">
                    <label for="flat6" :class="step.bazen > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Balkón</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_balkon }"
                       v-on:click="plocha_balkon = !plocha_balkon">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_balkon"
                            :class="{error: step.plocha_balkon <= 0 && validated, succes: step.plocha_balkon > 0}">
                    <input type="number" name="flat7" id="flat7" v-model="step.plocha_balkon">
                    <label for="flat7" :class="step.plocha_balkon > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Terasa</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_terasa }"
                       v-on:click="plocha_terasa = !plocha_terasa">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_terasa"
                            :class="{error: step.plocha_terasa <= 0 && validated, succes: step.plocha_terasa > 0}">
                    <input type="number" name="flat8" id="flat8" v-model="step.plocha_terasa">
                    <label for="flat8" :class="step.plocha_terasa > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Parkovací stání</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: park_pocet }" v-on:click="park_pocet = !park_pocet">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="park_pocet" :class="{error: step.park_pocet <= 0 && validated, succes: step.park_pocet > 0}">
                    <input type="number" name="flat7" id="flat9" v-model="step.park_pocet">
                    <label for="flat9" :class="step.park_pocet > 0 ? 'yes' : 'no'">Počet</label>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>


          <div class="list-content" v-if="zadanytyp === '4'">
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <h3>Číslo jednotky</h3>
                  <fieldset :class="{error: !step.cislo_jednotky && validated, succes: step.cislo_jednotky}">
                    <input type="text" name="in1" id="in1" v-model="step.cislo_jednotky">
                    <label for="in1" :class="step.cislo_jednotky > 0 ? 'yes' : 'no'">číslo jednotky bytu</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-2">
                  <h3>Počet podlaží domu</h3>
                  <fieldset :class="{error: !step.pater && validated, succes: step.pater}">
                    <input type="text" name="in2" id="in2" v-model="step.pater">
                    <label for="in2" :class="step.pater > 0 ? 'yes' : 'no'">Počet podlaží</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-2">
                  <h3>Podlaží vašeho bytu</h3>
                  <fieldset :class="{error: !step.patro && validated, succes: step.patro}">
                    <input type="text" name="in3" id="in3" v-model="step.patro">
                    <label for="in3" :class="step.patro > 0 ? 'yes' : 'no'">Podlaží</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-2">
                  <h3>Užitná plocha (m<sup>2</sup>)</h3>
                  <fieldset :class="{error: !step.uzit_plocha && validated, succes: step.uzit_plocha}">
                    <input type="number" name="in4" id="in4" v-model="step.uzit_plocha">
                    <label for="in4" :class="step.uzit_plocha > 0 ? 'yes' : 'no'">v m<sup>2</sup></label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <h3>Stav</h3>
              <fieldset :class="{error: !step.stav && validated, succes: step.stav}">
                <select class="uk-select" v-model="step.stav">
                  <option v-for="option in optionsStav" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Stavba</h3>
              <fieldset :class="{error: !step.postavenoz && validated, succes: step.postavenoz}">
                <select class="uk-select" v-model="step.postavenoz">
                  <option v-for="option in optionsPostaven" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Výtah</h3>
              <fieldset :class="{error: !step.vytah && validated, succes: step.vytah}">
                <select class="uk-select" v-model="step.vytah">
                  <option v-for="option in optionsVytah" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Vytápění</h3>
              <fieldset :class="{error: !step.vytapeni && validated, succes: step.vytapeni}">
                <select class="uk-select" v-model="step.vytapeni">
                  <option v-for="option in optionsVytapeni" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>

            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Sklep</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_sklep }" v-on:click="plocha_sklep = !plocha_sklep">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_sklep"
                            :class="{error: !step.plocha_sklep && validated, succes: step.plocha_sklep}">
                    <input type="number" name="flat3" id="flat3" v-model="step.plocha_sklep">
                    <label for="flat3" :class="step.plocha_sklep > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Lodžie</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_lodzie }"
                       v-on:click="plocha_lodzie = !plocha_lodzie">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_lodzie"
                            :class="{error: !step.plocha_lodzie && validated, succes: step.plocha_lodzie}">
                    <input type="number" name="flat4" id="flat4" v-model="step.plocha_lodzie">
                    <label for="flat4" :class="step.plocha_lodzie ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Garáž</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: pocet_garaz }" v-on:click="pocet_garaz = !pocet_garaz">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="pocet_garaz"
                            :class="{error: !step.pocet_garaz && validated, succes: step.pocet_garaz}">
                    <input type="text" name="flat5" id="flat5" v-model="step.pocet_garaz">
                    <label for="flat5" :class="step.pocet_garaz > 0 ? 'yes' : 'no'">Počet</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Balkón</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_balkon }"
                       v-on:click="plocha_balkon = !plocha_balkon">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_balkon"
                            :class="{error: !step.plocha_balkon && validated, succes: step.plocha_balkon}">
                    <input type="text" name="flat7" id="flat7" v-model="step.plocha_balkon">
                    <label for="flat7" :class="step.plocha_balkon > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Terasa</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_terasa }"
                       v-on:click="plocha_terasa = !plocha_terasa">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_terasa"
                            :class="{error: !step.plocha_terasa && validated, succes: step.plocha_terasa}">
                    <input type="text" name="flat8" id="flat8" v-model="step.plocha_terasa">
                    <label for="flat8" :class="step.plocha_terasa > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Parkovací stání</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: park_pocet }" v-on:click="park_pocet = !park_pocet">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="park_pocet" :class="{error: !step.park_pocet && validated, succes: step.park_pocet}">
                    <input type="text" name="flat7" id="flat9" v-model="step.park_pocet">
                    <label for="flat9" :class="step.park_pocet > 0 ? 'yes' : 'no'">Počet</label>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>

          <div class="list-content" v-if="zadanytyp === '3'">
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <h3>Parcelní číslo</h3>
                  <fieldset :class="{error: !step.id_parcela && validated, succes: step.id_parcela}">
                    <input type="text" name="in5" id="in5" v-model="step.id_parcela">
                    <label for="in5" :class="step.id_parcela > 0 ? 'yes' : 'no'">Např. 729/1</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-2">
                  <h3>Plocha pozemku (m<sup>2</sup>)</h3>
                  <fieldset :class="{error: !step.celkova_plocha && validated, succes: step.celkova_plocha}">
                    <input type="text" name="in6" id="in6" v-model="step.celkova_plocha">
                    <label for="in6" :class="step.celkova_plocha > 0 ? 'yes' : 'no'">v m<sup>2</sup></label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <h3>Inženýrské sítě</h3>
              <fieldset :class="{error: !step.inzsite && validated, succes: step.inzsite}">
                <select class="uk-select" v-model="step.inzsite">
                  <option v-for="option in optionsInzsite" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Odpad</h3>
              <fieldset :class="{error: !step.odpad && validated, succes: step.odpad}">
                <select class="uk-select" v-model="step.odpad">
                  <option v-for="option in optionsOdpad" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
          </div>
          <div class="list-content" v-if="zadanytyp === '2'">
            <div class="row">
              <h3>Plocha</h3>
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <fieldset :class="{error: !step.uzit_plocha && validated, succes: step.uzit_plocha}">
                    <input type="number" name="flat" id="flat" v-model="step.uzit_plocha">
                    <label for="flat" :class="step.uzit_plocha > 0 ? 'yes' : 'no'">Užitná plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-2">
                  <fieldset :class="{error: !step.plochapozem && validated, succes: step.plochapozem}">
                    <input type="number" name="flat2" id="flat2" v-model="step.plochapozem">
                    <label for="flat2" :class="step.plochapozem > 0 ? 'yes' : 'no'">Plocha pozemku
                      (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <h3>Stav</h3>
              <fieldset :class="{error: !step.stav && validated, succes: step.stav}">
                <select class="uk-select" v-model="step.stav">
                  <option v-for="option in optionsStav" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>

            </div>
            <div class="row">
              <h3>Stavba</h3>
              <fieldset :class="{error: !step.postavenoz && validated, succes: step.postavenoz}">
                <select class="uk-select" v-model="step.postavenoz">
                  <option v-for="option in optionsPostaven" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Typ domu</h3>
              <fieldset :class="{error: !step.poloha && validated, succes: step.poloha}">
                <select class="uk-select" v-model="step.poloha">
                  <option v-for="option in optionsPoloha" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Vytápění</h3>
              <fieldset :class="{error: !step.vytapeni && validated, succes: step.vytapeni}">
                <select class="uk-select" v-model="step.vytapeni">
                  <option v-for="option in optionsVytapeni" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Terasa</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: plocha_terasa }"
                       v-on:click="plocha_terasa = !plocha_terasa">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="plocha_terasa"
                            :class="{error: !step.plocha_terasa && validated, succes: step.plocha_terasa}">
                    <input type="number" name="flat8" id="flat8" v-model="step.plocha_terasa">
                    <label for="flat8" :class="step.plocha_terasa > 0 ? 'yes' : 'no'">Plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Garáž</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: pocet_garaz }" v-on:click="pocet_garaz = !pocet_garaz">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="pocet_garaz"
                            :class="{error: !step.pocet_garaz && validated, succes: step.pocet_garaz}">
                    <input type="number" name="flat5" id="flat5" v-model="step.pocet_garaz">
                    <label for="flat5" :class="step.pocet_garaz > 0 ? 'yes' : 'no'">Počet</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-3 fx"><h3>Parkovací stání</h3></div>
                <div class="uk-width-1-3 fx">
                  <div class="magic-button" :class="{ active: park_pocet }" v-on:click="park_pocet = !park_pocet">
                    <span>
                      <label><span>ne</span></label>
                    </span>
                    <span>
                      <label><span>ano</span></label>
                    </span>
                  </div>
                </div>
                <div class="uk-width-1-3 fx">
                  <fieldset v-if="park_pocet" :class="{error: !step.park_pocet && validated, succes: step.park_pocet}">
                    <input type="number" name="flat7" id="flat9" v-model="step.park_pocet">
                    <label for="flat9" :class="step.park_pocet > 0 ? 'yes' : 'no'">Počet</label>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
          <div class="list-content" v-if="zadanytyp === '11'">
            <div class="row">
              <h3>Plocha</h3>
              <div class="uk-grid-small" data-uk-grid>
                <div class="uk-width-1-2">
                  <fieldset :class="{error: !step.uzit_plocha && validated, succes: step.uzit_plocha}">
                    <input type="number" name="flat" id="flat" v-model="step.uzit_plocha">
                    <label for="flat" :class="step.uzit_plocha > 0 ? 'yes' : 'no'">Užitná plocha (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
                <div class="uk-width-1-2">
                  <fieldset :class="{error: (typeof step.plochapozem == 'undefined' || step.plochapozem === '') && validated, succes: step.plochapozem}">
                    <input type="number" name="flat2" id="flat2" v-model="step.plochapozem">
                    <label for="flat2" :class="(typeof step.plochapozem != 'undefined' || step.plochapozem !== '') ? 'yes' : 'no'">Plocha pozemku
                      (m<sup>2</sup>)</label>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="row">
              <h3>Stav</h3>
              <fieldset :class="{error: !step.stav && validated, succes: step.stav}">
                <select class="uk-select" v-model="step.stav">
                  <option v-for="option in optionsStav" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
            <div class="row">
              <h3>Stavba</h3>
              <fieldset :class="{error: !step.postavenoz && validated, succes: step.postavenoz}">
                <select class="uk-select" v-model="step.postavenoz">
                  <option v-for="option in optionsPostaven" :key="option.value" :value="option.value">
                    {{ option.text }}
                  </option>
                </select>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <h3>Faktické vady (např. vlhkost nebo porušená statika)</h3>
            <fieldset :class="{error: !vady && validated, succes: vady}">
              <select class="uk-select" v-model="vady">
                <option v-for="option in optionsVady" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
              <p class="error" v-if="validated && !faktValid">Vyberte alespoň jedno faktickou vadu</p>
            </fieldset>
            <div v-if="vady == 1" class="content-small">
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Nadměrná (vzlinající) vlhkost</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active8 }" v-on:click="active8 = !active8"
                         @click="step.fakt03 = active8">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Poškozená střecha</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active9 }" v-on:click="active9 = !active9"
                         @click="step.fakt01 = active9">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Omezená přístupová cesta</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active10 }" v-on:click="active10 = !active10"
                         @click="step.fakt07 = active10">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Poškození nosných konstrukcí</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active11 }" v-on:click="active11 = !active11"
                         @click="step.fakt02 = active11">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Nefunkční elektroinstalace</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active12 }" v-on:click="active12 = !active12"
                         @click="step.fakt04 = active12">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Přerušený přívod vody/plynu</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active13 }" v-on:click="active13 = !active13"
                         @click="step.fakt05 = active13">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3 fx"><h3>Jiné</h3></div>
                  <div class="uk-width-1-3 fx">
                    <div class="magic-button" :class="{ active: active14 }" v-on:click="active14 = !active14"
                         @click="step.fakt09 = active14">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label for="yes14"><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                  <div class="uk-width-1-1 fx">
                    <fieldset v-if="active14" :class="{error: !step.fakt10 && validated, succes: step.fakt10}" style="width: 100%;">
                      <input type="text" name="flat14" id="flat14" v-model="step.fakt10">
                      <label for="flat14" class="yes">Jaké? </label>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <h3>Právní vady (např. hypotéka)</h3>
            <fieldset :class="{error: !pravniVady && validated, succes: pravniVady}">
              <select class="uk-select" v-model="pravniVady">
                <option v-for="option in optionsPravniVady" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
              <p class="error" v-if="validated && !paktValid">Vyberte alespoň jednu právní vadu</p>
            </fieldset>
            <div v-if="pravniVady == 1" class="content-small">
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Hypotéka</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active15 }" v-on:click="active15 = !active15"
                         @click="step.pakt05 = active15">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Předkupní právo</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active16 }" v-on:click="active16 = !active16"
                         @click="step.pakt04 = active16">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Exekuce</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active17 }" v-on:click="active17 = !active17"
                         @click="step.pakt02 = active17">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Spor o vlastnictví nemovitosti</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active18 }" v-on:click="active18 = !active18"
                         @click="step.pakt08 = active18">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Zákaz převodu nemovitosti</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active19 }" v-on:click="active19 = !active19"
                         @click="step.pakt07 = active19">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Jiné zástavní právo</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active20 }" v-on:click="active20 = !active20"
                         @click="step.pakt06 = active20">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Insolvence</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active21 }" v-on:click="active21 = !active21"
                         @click="step.pakt03 = active21">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Dluh vůči společenství vlastníků</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active22 }" v-on:click="active22 = !active22"
                         @click="step.pakt01 = active22">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3 fx"><h3>Jiné</h3></div>
                  <div class="uk-width-1-3 fx">
                    <div class="magic-button" :class="{ active: active23 }" v-on:click="active23 = !active23"
                         @click="step.pakt10 = active23">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                  <div class="uk-width-1-1 fx">
                    <fieldset v-if="active23" :class="{error: !step.pakt11 && validated, succes: step.pakt11}" style="width: 100%;">
                      <input type="text" name="flat23" id="flat23" v-model="step.pakt11">
                      <label for="flat23" class="yes">Jaké? </label>
                    </fieldset>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <h3>Věcné břemeno</h3>
            <fieldset :class="{error: !bremeno && validated, succes: bremeno}">
              <select class="uk-select" v-model="bremeno">
                <option v-for="option in optionsBremeno" :key="option.value" :value="option.value">
                  {{ option.text }}
                </option>
              </select>
              <p class="error" v-if="validated && !caktValid">Vyberte alespoň jedno věcné břemeno</p>
            </fieldset>
            <div v-if="bremeno == 1" class="content-small">
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Služebnost inženýrské sítě</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active24 }" v-on:click="active24 = !active24"
                         @click="step.cakt01 = active24">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Služebnost bytu</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active25 }" v-on:click="active25 = !active25"
                         @click="step.cakt08 = active25">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Opora cizí stavby</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active26 }" v-on:click="active26 = !active26"
                         @click="step.cakt02 = active26">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Právo na svod dešt. vody</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active27 }" v-on:click="active27 = !active27"
                         @click="step.cakt03 = active27">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Užívací právo</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active28 }" v-on:click="active28 = !active28"
                         @click="step.cakt06 = active28">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Služebnost stezky, průhonu a cesty</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active29 }" v-on:click="active29 = !active29"
                         @click="step.cakt04 = active29">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Používací právo</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active30 }" v-on:click="active30 = !active30"
                         @click="step.cakt07 = active30">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="uk-grid-small" data-uk-grid>
                  <div class="uk-width-2-3">
                    <h3>Právo pastvy</h3>
                  </div>
                  <div class="uk-width-1-3">
                    <div class="magic-button" :class="{ active: active31 }" v-on:click="active31 = !active31"
                         @click="step.cakt05 = active31">
                        <span>
                          <label><span>ne</span></label>
                        </span>
                      <span>
                          <label><span>ano</span></label>
                        </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-1-1"><h3>Penb - Průkaz energetické náročnosti budovy</h3></div>
              <div class="uk-width-1-3">
                <div class="magic-button" :class="{ active: activeEner }" v-on:click="activeEner = !activeEner"
                     @click="energena = !energena">
                  <span>
                    <label><span>ne</span></label>
                  </span>
                  <span>
                    <label><span>ano</span></label>
                  </span>
                </div>
              </div>
              <div class="uk-width-2-3">
                <fieldset v-if="energena == 1" :class="{error: Boolean(step.energ) <= 0 && validated}">
                  <select class="uk-select" v-model="step.energ">
                    <option v-for="option in optionsEnerg" :key="option.value" :value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="row">
            <h3>Cena</h3>
            <div class="uk-grid-small" data-uk-grid>
              <div class="uk-width-2-3 fx">
                <fieldset :class="{error: (!step.cena || step.cena <= 0) && validated, succes: step.cena > 0}" class="mena-wrapper">
                <p class="mena-formatted"></p>
                  <input type="number" name="price" id="price" v-model="step.cena" @input="formatPrice()">
                  <label for="price" :class="step.cena > 0 ? 'yes' : 'no'">*Cena nemovitosti</label>
                </fieldset>
              </div>
              <div class="uk-width-1-3 fx">
                <fieldset :class="{error: (!step.mena || step.mena <= 0) && validated, succes: step.mena > 0}">
                  <select class="uk-select" v-model="step.mena">
                    <option v-for="option in optionsMena" :key="option.value" :value="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </fieldset>
              </div>
            </div>
          </div>
          <div class="row" v-if="(zadanytyp == 4 || zadanytyp == 6) && add.steps[1].vlastnictvi != 7">
            <div class="info-panel">
              <p>Odhad ceny vyší nemovitosti</p>
              <p>
                <a @click="valuoRequest()" class="button white small">Odhadnout cenu</a>
              </p>
              <p>Odhad ceny spočítalo valuo.cz. odhad ceny je jen subjektivním názorem společnosti Value Technologies
                s.r.o. a může se tak lišit od obvyklé ceny stanovené certifikovaným odhadcem nemovitosti</p>
            </div>
          </div>
          <div class="row">
            <fieldset>
              <textarea id="textname" v-model="step.cenatext"></textarea>
              <label for="textname" class="yes">Dodatek k ceně</label>
            </fieldset>
          </div>
          <div class="row end">
            <a @click="moveToStep('firstStep', true )" class="button white small">Zpět</a>
            <a @click="moveToStep('thirdStep')" class="button small">Pokračovat dále</a>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>

import FirstStep from "@/views/steps/FirstStep";
import valuoMapper from "../../../libs/valuoMapper";
import Delayer from "../../../libs/delayer";
// import axios from 'axios';

export default {
  name: "SecondStep",
  components: {FirstStep},
  data() {
    return {
      id: null,
      add: null,
      zadanytyp: null,
      step: {
        uzit_plocha: "",
        plochapozem: "",
        stav: "",
        postavenoz: "",
        poloha: "",
        vytapeni: "",
        plocha_sklep: "",
        plocha_lodzie: "",
        pocet_garaz: "",
        bazen: "",
        plocha_balkon: "",
        plocha_terasa: "",
        park_pocet: "",
        fakt03: "",
        fakt01: "",
        fakt07: "",
        fakt02: "",
        fakt04: "",
        fakt05: "",
        fakt09: "",
        fakt10: "",
        pakt05: "",
        pakt04: "",
        pakt02: "",
        pakt08: "",
        pakt07: "",
        pakt06: "",
        pakt03: "",
        pakt01: "",
        pakt10: "",
        pakt11: "",
        cakt01: "",
        cakt02: "",
        cakt03: "",
        cakt04: "",
        cakt05: "",
        cakt06: "",
        cakt07: "",
        cakt08: "",
        energ: "",
        cena: null,
        mena: 1,
        cenatext: "",

        cislo_jednotky: "",
        pater: "",
        patro: "",

        id_parcela: "",
        celkova_plocha: "",
        inzsite: "",
        odpad: "",
      },
      optionsStav: [
        {text: 'výborný', value: '1'},
        {text: 'dobrý', value: '2'},
        {text: 'nutná rekonstrukce', value: '3'},
        {text: 'špatný', value: '4'},
        {text: 'po rekonstrukci', value: '5'},
        {text: 'novostavba', value: '6'},
        {text: 've výstavbě', value: '7'},
      ],
      optionsPostaven: [
        {text: 'cihla', value: '1'},
        {text: 'panel', value: '2'},
        {text: 'dřevostavba', value: '3'},
        {text: 'smíšené', value: '35'},
        {text: 'jiné', value: '10'},
      ],
      optionsPoloha: [
        {text: 'řadový', value: '1'},
        {text: 'rohový', value: '2'},
        {text: 'samostatný', value: '4'},
        {text: 'dvojdomek', value: '25'},
        {text: 'přízemí', value: '26'},
        {text: 'patrový', value: '27'},
        {text: 'dvougenerační', value: '28'},
        {text: 'komplex budov', value: '29'},
      ],
      optionsVytapeni: [
        {text: 'lokální plynové', value: '1'},
        {text: 'lokální tuhá', value: '2'},
        {text: 'lokální elektrické', value: '3'},
        {text: 'jiné', value: '8'},
      ],
      optionsMena: [
        {text: 'Kč', value: '1'},
        {text: 'Kč/m2', value: '4'},
        {text: 'Eur', value: '11'},
        {text: 'Eur/m2', value: '44'},
      ],
      optionsInzsite: [
        {text: 'ne', value: '0'},
        {text: 'blízko', value: '1'},
        {text: 'ano', value: '2'},
      ],
      optionsOdpad: [
        {text: 'ne', value: '0'},
        {text: 'veřejná kanalizace', value: '1'},
        {text: 'ČOV pro celý objekt', value: '2'},
        {text: 'septik', value: '3'},
        {text: 'jímka', value: '4'},
      ],
      preiousStep: {
        typ: 0
      },
      file: null,
      previewImage: null,
      plocha_sklep: false,
      plocha_lodzie: false,
      pocet_garaz: false,
      bazen: false,
      plocha_balkon: false,
      plocha_terasa: false,
      park_pocet: false,
      active8: false,
      active9: false,
      active10: false,
      active11: false,
      active12: false,
      active13: false,
      active14: false,
      active15: false,
      active16: false,
      active17: false,
      active18: false,
      active19: false,
      active20: false,
      active21: false,
      active22: false,
      active23: false,
      active24: false,
      active25: false,
      active26: false,
      active27: false,
      active28: false,
      active29: false,
      active30: false,
      active31: false,
      activeEner: false,
      energena: "",
      vady: null,
      vytah: "",
      optionsVytah: [
        {text: 'ano', value: '1'},
        {text: 'ne', value: '2'},
      ],
      optionsVady: [
        {text: 'ano', value: '1'},
        {text: 'ne', value: '2'},
      ],
      pravniVady: null,
      optionsPravniVady: [
        {text: 'ano', value: '1'},
        {text: 'ne', value: '2'},
      ],
      bremeno: null,
      optionsBremeno: [
        {text: 'ano', value: '1'},
        {text: 'ne', value: '2'},
      ],
      optionsEnerg: [
        {text: 'A - Mimořádně úsporná', value: '1'},
        {text: 'B - Velmi úsporná', value: '2'},
        {text: 'C - Úsporná', value: '3'},
        {text: 'D - Méně úsporná', value: '4'},
        {text: 'E - Nehospodárná', value: '5'},
        {text: 'F - Velmi nehospodárná', value: '6'},
        {text: 'G - Mimořádně nehospodárná', value: '7'},
      ],

      faktValid: false,
      caktValid: false,
      paktValid: false,
      validated: false,
    }
  },
  created() {
    this.id = this.$route.params.id;
    let add = this.$store.getters.advertById(this.id);
    if( typeof add == 'undefined' ) {
      this.$router.push({ path: '/' });
      return;
    }
    if( add.activated ){
      this.$router.push({name: 'viewonly', params: {id: this.id}})
    }

    this.add = add;
    this.zadanytyp = add.steps["1"].zadanytyp;
    this.step = add.steps["2"];

    this.initCakt();
    this.initFakt();
    this.initPakt();
    this.initPenb();
    this.initOptional();
    
  },
  mounted(){
    this.formatPrice();
  },
  methods: {
    async valuoRequest(){
      const data = {
        place: this.add.address.latitude + ', ' + this.add.address.longitude,
        kind: "sale",
        property_type: this.zadanytyp == 6 ? 'house' : 'flat',
        rating: valuoMapper.getRating( this.step.stav),
        ownership: valuoMapper.getOwnerShip(this.add.steps[1].vlastnictvi ),
        floor_area: this.step.uzit_plocha,
        lot_area: this.step.plochapozem,
        energy_performance: valuoMapper.energy(this.step.energ),
        construction: valuoMapper.getConstruction(this.step.postavenoz),
      };

      if( this.zadanytyp == 4 ){
        data.floor =  this.step.patro;
        data.total_floors = this.step.pater;
        data.local_type = valuoMapper.getLocalType(this.add.steps[1].druh);
      }
     if( this.zadanytyp == 6 ){
        data.house_type = valuoMapper.getHouseType(this.add.steps[1].druh);
      }
      if( this.plocha_lodzie > 0){
        data.loggia_area = this.plocha_lodzie;
      }
      if( this.plocha_sklep > 0){
        data.cellar_area = this.plocha_sklep;
      }
      if( this.plocha_terasa > 0){
        data.terrace_area = this.plocha_terasa;
      }
      if( this.plocha_balkon > 0){
        data.balcony_area = this.plocha_balkon;
      }
      if( this.pocet_garaz > 0){
        data.garages = this.pocet_garaz;
      }
      let regOptions = {
        method: "POST",
        headers: { "Authorization": "Bearer gOcKtNb0FFhIhShTIy60","Content-Type":"application/json"},
        body: JSON.stringify(data),
      };
      
      // old endpoint until 2024: https://api.valuo.cz/v1/calculation
      try{
        const response = await fetch( "https://v2p.api.valuo.cz/market-value", regOptions );
        // console.log(response);
        const responseData = await response.json();
        // console.log(responseData);
            // console.log(responseData?.errors.length);
            // if( responseData?.errors.length ){
            //   alert( responseData?.errors[0] );
            //   return;
            // }
            this.step.mena = 1;
            this.step.cena = responseData.result.avg_price;
            setTimeout(()=>{
              this.formatPrice();
            },150);
      } catch(error){
        console.log(error);
      }
      // console.log(data);
        // .then(response => {
        //   response.json();
        // }).then(data => {
          
        //     console.log(data);
        //     console.log(data?.errors.length);
        //     if( data?.errors.length ){
        //       alert( data?.errors[0] );
        //       return;
        //     }
        //     this.step.mena = 1;
        //     this.step.cena = data.result.avg_price;
        // }).catch(error => {
        //   console.log(error);
        // })
    },
    async save() {
      let valid = this.validate();
      if (!valid) {
        Delayer.delay(600).then(()=> {
          document.querySelector(".error").scrollIntoView( { behavior: 'smooth'});
        });
        return false;
      }
      if (this.vady == 2){
        this.step.fakt01 = 0;
        this.step.fakt02 = 0;
        this.step.fakt03 = 0;
        this.step.fakt04 = 0;
        this.step.fakt05 = 0;
        this.step.fakt07 = 0;
        this.step.fakt09 = 0;
        this.step.fakt10 = 0;
      }
      if( this.pravniVady == 2){
        this.step.pakt01 = 0;
        this.step.pakt02 = 0;
        this.step.pakt03 = 0;
        this.step.pakt04 = 0;
        this.step.pakt05 = 0;
        this.step.pakt06 = 0;
        this.step.pakt07 = 0;
        this.step.pakt08 = 0;
        this.step.pakt10 = 0;
        this.step.pakt11 = 0;
      }
      if( this.bremeno == 2){
        this.step.cakt01 = 0;
        this.step.cakt02 = 0;
        this.step.cakt03 = 0;
        this.step.cakt04 = 0;
        this.step.cakt05 = 0;
        this.step.cakt06 = 0;
        this.step.cakt07 = 0;
        this.step.cakt08 = 0;
      }
      if( !this.energena ) {
        this.step.energ = null;
      }
      this.step.vady = this.vady;
      this.step.pravniVady = this.pravniVady;
      this.step.bremeno = this.bremeno;

      let advert = {
        id: this.id,
        step: this.step
      };
      await this.$store.dispatch('updateStepTwo', advert);
      return true;
    },
    async moveToStep(stepName, notValidate = false ) {
      if (await this.save() || notValidate ) {
        this.$router.push({name: stepName, params: {id: this.id}});
      }
    },
    stepHasValues(step) {
      if( !this.add ){
        return false;
      }
      return Object.keys(this.add.steps[step]).length > 0;
    },
    validate() {
      let valid = true;
      this.faktValid = true;
      this.caktValid = true
      this.paktValid = true;

      if( !this.step.cena || this.step.cena <= 0 || !this.step.mena || this.step.mena <= 0 ){
        valid = false;
      }

      //validace domy a chaty
      if (this.zadanytyp == 6) {
        if (!this.step.uzit_plocha || !this.step.plochapozem || !this.step.stav || !this.step.postavenoz || !this.step.poloha || !this.step.vytapeni)
          valid = false

        if (this.plocha_sklep && this.step.plocha_sklep <= 0 )
          valid = false;
        if (this.plocha_lodzie && this.step.plocha_lodzie <= 0)
          valid = false;
        if (this.bazen && this.step.bazen <= 0)
          valid = false;
        if (this.plocha_balkon && this.step.plocha_balkon <= 0)
          valid = false;
        if (this.plocha_terasa && this.step.plocha_terasa <= 0)
          valid = false;
        if (this.park_pocet && this.step.park_pocet <= 0)
          valid = false;
      }

      //validace bytů
      if (this.zadanytyp == 4) {
        if (!this.step.cislo_jednotky || !this.step.pater || !this.step.patro || !this.step.uzit_plocha || !this.step.stav || !this.step.postavenoz || !this.step.vytah || !this.step.vytapeni)
          valid = false;

        if (this.plocha_sklep && this.step.plocha_sklep <= 0 )
          valid = false;
        if (this.plocha_lodzie && this.step.plocha_lodzie <= 0)
          valid = false;
        if (this.pocet_garaz && this.step.pocet_garaz <= 0)
        valid = false;
        if (this.plocha_balkon && this.step.plocha_balkon <= 0)
          valid = false;
        if (this.plocha_terasa && this.step.plocha_terasa <= 0)
          valid = false;
        if (this.park_pocet && this.step.park_pocet <= 0)
          valid = false;
      }

      if( this.zadanytyp == 3){
        if( !this.step.id_parcela || !this.step.celkova_plocha || !this.step.inzsite || !this.step.odpad )
          valid = false;
      }

      if( this.zadanytyp == 2 ){
        if( !this.step.uzit_plocha || !this.step.plochapozem || !this.step.stav || !this.step.postavenoz || !this.step.poloha || !this.step.vytapeni )
          valid = false;

        if (this.plocha_terasa && this.step.plocha_terasa <= 0)
          valid = false;
        if (this.pocet_garaz && this.step.pocet_garaz <= 0)
          valid = false;
        if (this.park_pocet && this.step.park_pocet <= 0)
          valid = false;
      }



      if( this.zadanytyp == 11 ){
        if( !this.step.uzit_plocha || !this.step.stav || !this.step.postavenoz )
          valid = false;

        if( typeof this.step.plochapozem == 'undefined' || this.step.plochapozem.length == 0 )
          valid = false;
      }
      if( !this.vady ){
        valid = false;
      }else{
        if( this.vady == 1)
        {
          if(
            Number(this.step.fakt01) != 1 &&
            Number(this.step.fakt02) != 1 &&
            Number(this.step.fakt03) != 1 &&
            Number(this.step.fakt04) != 1 &&
            Number(this.step.fakt05) != 1 &&
            Number(this.step.fakt07) != 1 &&
            Number(this.step.fakt09) != 1 &&
            Number(this.step.fakt10) !== 1
          ){
            valid = false;
            this.faktValid = false;
          }

          if( Number(this.step.fakt09) == 1 && !this.step.fakt10 ){
            valid = false;
          }
        }
      }

      if( !this.pravniVady){
        valid = false;
      }else{
        if( this.pravniVady == 1)
        {
          if(
            Number(this.step.pakt01) != 1 &&
            Number(this.step.pakt02) != 1 &&
            Number(this.step.pakt03) != 1 &&
            Number(this.step.pakt04) != 1 &&
            Number(this.step.pakt05) != 1 &&
            Number(this.step.pakt06) != 1 &&
            Number(this.step.pakt07) != 1 &&
            Number(this.step.pakt08) != 1 &&
            Number(this.step.pakt10) != 1 &&
            Number(this.step.pakt11) != 1
          ){
            valid = false;
            this.paktValid = false;
          }

          if( Number(this.step.pakt10) && !this.step.pakt11 ){
            valid = false;
          }
        }
      }

      if( !this.bremeno ){
        valid = false;
      }else{
        if( this.bremeno == 1)
        {
          if(
            Number(this.step.cakt01) != 1 &&
            Number(this.step.cakt02) != 1 &&
            Number(this.step.cakt03) != 1 &&
            Number(this.step.cakt04) != 1 &&
            Number(this.step.cakt05) != 1 &&
            Number(this.step.cakt06) != 1 &&
            Number(this.step.cakt07) != 1 &&
            Number(this.step.cakt08 ) != 1
          ){
            valid = false;
            this.caktValid = false;
          }
        }
      }

      if( this.activeEner && !this.step.energ ) {
        valid = false;
      }

      this.validated = true;
      return valid;
    },
    initPakt(){
      if( Number(this.step.pakt05) ){
        this.active15 = true;
        this.pravniVady = 1;
      }
      if( Number(this.step.pakt04) ){
        this.active16 = true;
        this.pravniVady = 1;
      }
      if( Number(this.step.pakt02) ){
        this.active17 = true;
        this.pravniVady = 1;
      }
      if( Number(this.step.pakt08) ){
        this.active18 = true;
        this.pravniVady = 1;
      }
      if( Number(this.step.pakt07) ){
        this.active19 = true;
        this.pravniVady = 1;
      }
      if( Number(this.step.pakt06) ){
        this.active20 = true;
        this.pravniVady = 1;
      }
      if( Number(this.step.pakt03) ){
        this.active21 = true;
        this.pravniVady = 1;
      }
      if( Number(this.step.pakt01) ){
        this.active22 = true;
        this.pravniVady = 1;
      }
      if( Number(this.step.pakt11) ){
        this.active23 = true;
        this.pravniVady = 1;
      }

      if( this.step.pravniVady ){
        this.pravniVady = this.step.pravniVady;
      }

    },
    initFakt(){
      if( Number(this.step.fakt03) ){
        this.active8 = true;
        this.vady = 1;
      }
      if( Number(this.step.fakt01) ){
        this.active9 = true;
        this.vady = 1;
      }
      if( Number(this.step.fakt07) ){
        this.active10 = true;
        this.vady = 1;
      }
      if( Number(this.step.fakt02) ){
        this.active11 = true;
        this.vady = 1;
      }
      if( Number(this.step.fakt04) ){
        this.active12 = true;
        this.vady = 1;
      }
      if( Number(this.step.fakt05) ){
        this.active13 = true;
        this.vady = 1;
      }
      if( Number(this.step.fakt09) ){
        this.active14 = true;
        this.vady = 1;
      }
      if( Number(this.step.fakt09) ){
        this.active14 = true;
        this.vady = 1;
      }

      if( this.step.vady ){
        this.vady = this.step.vady;
      }
    },
    initOptional(){
      if( Number(this.step.plocha_sklep ) ){
        this.plocha_sklep = true;
      }

      if( Number(this.step.plocha_lodzie ) ){
        this.plocha_lodzie = true;
      }

      if( Number(this.step.pocet_garaz ) ){
        this.pocet_garaz = true;
      }

      if( Number(this.step.bazen ) ){
        this.bazen = true;
      }

      if( Number(this.step.plocha_balkon ) ){
        this.plocha_balkon = true;
      }

      if( Number(this.step.plocha_terasa ) ){
        this.plocha_terasa = true;
      }

      if( Number(this.step.park_pocet ) ){
        this.park_pocet = true;
      }
    },
    initPenb(){
      if( this.step.energ ){
        this.energena = true;
        this.activeEner = true;
      }
    },
    initCakt(){
      if( Number(this.step.cakt01) ){
        this.active24 = true;
        this.bremeno = 1;
      }
      if( Number(this.step.cakt08) ){
        this.active25 = true;
        this.bremeno = 1;
      }
      if( Number(this.step.cakt02) ){
        this.active26 = true;
        this.bremeno = 1;
      }
      if( Number(this.step.cakt03) ){
        this.active27 = true;
        this.bremeno = 1;
      }
      if( Number(this.step.cakt06 ) ){
        this.active28 = true;
        this.bremeno = 1;
      }
      if( Number(this.step.cakt04 ) ){
        this.active29 = true;
        this.bremeno = 1;
      }
      if( Number(this.step.cakt07 )){
        this.active30 = true;
        this.bremeno = 1;
      }
      if( Number(this.step.cakt05 )){
        this.active31 = true;
        this.bremeno = 1;
      }

      if( this.step.bremeno ){
        this.bremeno = this.step.bremeno;
      }
    },
    formatPrice(){
      const formatted = document.querySelector('.mena-formatted');
      const input = document.querySelector('#price');
      if(String(input.value)[0] === '0'){
        input.value = Number(String(input.value).replace(/^0+/, ''));
      }
      input.style.paddingLeft = '2rem';
      formatted.textContent = new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK'}).format(input.value).slice(0,-3);
      if(input.value.length>3){
        input.style.paddingLeft = '2.3rem';
      }
      if(input.value.length>6){
        input.style.paddingLeft = '2.5rem';
      }
      if(input.value.length>9){
        input.style.paddingLeft = '2.7rem';
      }
      if(input.value.length>12){
        input.style.paddingLeft = '2.9rem';
      }
    }
  }
  
}
</script>

<style lang="less">
section.multistep {
  .container {
    position: relative;
    max-width: 42.8rem;
    height: 92vh;
    overflow: auto;
    margin: auto;
    background: #fff;
    box-sizing: border-box;
    padding-bottom: 4rem;

    .steps-count {
      background: #fff;
      padding: 1.8rem 0;

      .steps {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
        max-width: 27rem;
        margin: auto;

        a {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          width: 4.3rem;
          height: 4.3rem;
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 2.1rem;
          line-height: 2.1rem;
          color: #03A3BD;
          background: #F2F2F2;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
          -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);

          &.active {
            background: #03A3BD;
            color: #fff;

            &:hover {
              background: #fff;
              color: #03A3BD;
            }
          }

          &:hover {
            text-decoration: none;
            background: #03A3BD;
            color: #fff;
          }

          &:before {
            content: "";
            position: absolute;
            top: 0;
            right: -2rem;
            bottom: 0;
            margin: auto;
            width: 0.9rem;
            height: 1.7rem;
            background: url('../../assets/images/ico-arrow-right.png');
            background-size: cover;
            background-repeat: no-repeat;
            z-index: 1;
          }

          &:last-of-type {
            &:before {
              content: none;
            }
          }
        }
      }
    }

    .content {
      padding: 2rem 2rem 6rem;

      h1 {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 3.5rem;
        text-align: center;
        color: #7C7C7B;
        margin-top: 0;
        margin-bottom: 1rem;
      }

      p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 2.3rem;
        text-align: center;
        max-width: 33rem;
        margin: auto;
        margin-top: 0;
        margin-bottom: 4rem;
      }

      form {
        .row {
          h3 {
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.1rem;
            color: #03A3BD;
            margin-top: 2rem;
            margin-bottom: 1rem;
          }

          .uk-select {
            position: relative;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 2rem;
            width: 100%;
            height: 4rem;
            border: 1px solid #D6D6D6;
            -webkit-border-radius: 0.5rem;
            -moz-border-radius: 0.5rem;
            border-radius: 0.5rem;
            font-family: 'Poppins';
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 2.1rem;
            color: #7C7C7B;
          }

          .info-panel {
            background: #F2F2F2;
            padding: 2rem;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            margin: 1rem 0;

            p {
              font-family: 'Poppins';
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 2.1rem;
              color: #7C7C7B;
              margin-top: 1rem;
              margin-bottom: 1rem;
            }
          }

          .uk-grid {
            > div {
              .magic-button {
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                border: 1px solid #D6D6D6;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                -webkit-border-radius: 2rem;
                -moz-border-radius: 2rem;
                border-radius: 2rem;
                overflow: hidden;
                max-width: 8.2rem;

                &:before {
                  content: "";
                  position: absolute;
                  top: 1px;
                  left: 4.6rem;
                  width: 2.7rem;
                  height: 2.7rem;
                  background: #fff;
                  display: block;
                  border-radius: 50%;
                  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
                  -webkit-transition-duration: 0.2s;
                  -o-transition-duration: 0.2s;
                  transition-duration: 0.2s;
                  z-index: 2;
                }

                span {
                  position: relative;

                  input {
                    position: absolute;
                    opacity: 0;
                    width: 4rem;
                    height: 3.1rem;
                    margin: 0;
                    z-index: 3;
                    /*&:checked + label{
                      background: #2FAC66;
                      color: #fff;
                    }*/
                  }

                  label {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    width: 4rem;
                    height: 3.1rem;
                    font-family: 'Poppins';
                    font-weight: 400;
                    font-size: 1.4rem;
                    line-height: 2.1rem;
                    color: #7C7C7B;
                    background: #F2F2F2;
                  }
                }

                &.active {
                  border-color: #2FAC66;

                  &:before {
                    left: 0.6rem;
                  }

                  span {
                    label {
                      background: #2FAC66;
                      color: #fff;
                    }
                  }
                }
              }

              &.fx {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                margin-top: 3rem;

                fieldset {
                  margin-top: 0;
                }
              }
            }
          }

          fieldset {
            margin-top: 3rem;

            input {
              position: relative;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 0 2rem;
              width: 100%;
              height: 4rem;
              border: 1px solid #D6D6D6;
              -webkit-border-radius: 0.5rem;
              -moz-border-radius: 0.5rem;
              border-radius: 0.5rem;
              font-family: 'Poppins';
              font-weight: 700;
              font-size: 1.4rem;
              line-height: 2.1rem;
              color: #7C7C7B;

              &:focus + label {
                -webkit-transform: translate3d(0, -2.5rem, 0);
                transform: translate3d(0, -2.5rem, 0);
              }
            }

            label {
              top: 0.8rem;
              font-size: 11px;
              -webkit-transform: translate3d(0, -0rem, 0);
              transform: translate3d(0, -0rem, 0);
              z-index: 10;

              &.yes {
                font-size: 11px;
                -webkit-transform: translate3d(0, -2.5rem, 0);
                transform: translate3d(0, -2.5rem, 0);
                border: 1px solid #b8b8b8;
                padding: 3px;
                background: #fff;
                border-radius: 3px;
              }
            }

            > span.error {
              position: absolute;
              top: 0.5rem;
              right: 1.1rem;
              font-family: 'Poppins';
              font-weight: 400;
              font-size: 1.2rem;
              line-height: 1.8rem;
              color: #AC2F2F;
              background: #fff;
              z-index: 2;
              height: 3rem;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
            }

            label {
              left: 0.8rem;
            }

            textarea {
              position: relative;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              padding: 2rem;
              width: 100%;
              height: 10rem;
              border: 1px solid #D6D6D6;
              -webkit-border-radius: 0.5rem;
              -moz-border-radius: 0.5rem;
              border-radius: 0.5rem;
              font-family: 'Poppins';
              font-weight: 700;
              font-size: 1.4rem;
              line-height: 2.1rem;
              color: #7C7C7B;

              &:focus + label {
                -webkit-transform: translate3d(0, -2.5rem, 0);
                transform: translate3d(0, -2.5rem, 0);
              }
            }

            &.error {
              input, textarea, select {
                border-color: #AC2F2F;
              }

              label {
                border-color: #AC2F2F;
                color: #AC2F2F;
              }
            }

            &.succes {
              input, textarea, select {
                border-color: #04ac66;
              }

              label {
                border-color: #04ac66;
                color: #04ac66;
              }
            }
          }

          &.end {
            padding: 2rem 0;
            display: flex;
            justify-content: space-between;
          }

          .content-small {
            position: relative;

            .row {
              margin-top: 1rem;

              .uk-grid {
                > div {
                  h3 {
                    margin-top: 0.7rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .mena-wrapper{
    position:absolute;
    z-index:0;  
  }
  #price{
    z-index:0;
    color:transparent;
    caret-color:#7C7C7B;
  }
  .mena-formatted{
    position:absolute;
    background:rgba(255,255,255,1);
    left:0;
    top:0.8rem;
    bottom:0;
    width:auto;
    text-align:left;
    z-index:1;
    padding:0rem 2rem;
    font-size: 1.4rem !important;
    font-weight:700 !important;
    color:#7C7C7B;
  }
}
</style>